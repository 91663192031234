export const applicationFormField = {
  aadhaar_card_number: "Aadhar Number",
  benefits: "Benefits",
  created_at: "Registered On",
  date_of_birth: "Date Of Birth",
  disability_description: "Disability Description",
  education_qualification: "Education Qualification",
  full_name: "Full Name",
  gender: "Gender",
  have_children: "Have Children",
  id: "ID",
  is_disabled: "Disabled",
  is_previous_saathi_member: "Previous Saathi Member",
  is_retired_person: "Retired Person",
  marital_status: "Marital Status",
  mobile_number: "Mobile Number",
  monthly_salary_range: "Monthly Salary Range",
  number_of_children: "Number Of Children",
  pan_card_number: "Pan Card Number",
  pincode: "Pincode",
  previous_saathi_card_no: "Previous Card No",
  registered_member_of_fwice: "Registered Member Of Film Union",
  require_scholarship: "Require Scholarship",
  residential_address: "Address",
  sector_of_film_industry: "Sector Of Film Industry",
  status: "Status",
  type_of_house: "Type of house",
  bank_name: "Bank Name",
  bank_account_number: "Bank Account Number",
  ifsc_code : "IFSC Code",
  bank_branch_name: "Bank Branch Name",
  ayushman_bharat_card: "Ayushman Bharat Card",
  ration_card_color: "Ration Card Color",
  have_ration_card: "Have Ration Card"
};
