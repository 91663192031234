import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Box, IconButton, Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import ViewApplication from "../../components/Applications/ViewApplication";
import {
  useDeleteApplicationFormMutation,
  useGetAllAcceptedApplicationFormMutation,
  useGetAllPendingApplicationFormMutation,
  useGetAllRejectedApplicationFormMutation,
} from "../../features/applicationForm/applicationFormApiSlice";
import { toast } from "react-toastify";

const NewApplication = () => {
  const [openViewModal, setOpenViewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requestType, setRequestType] = useState("PENDING");
  const [applicationData, setApplicationData] = useState(null);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const [getAllPendingApplicationForm] = useGetAllPendingApplicationFormMutation();
  const [getAllAcceptedApplicationForm] = useGetAllAcceptedApplicationFormMutation();
  const [getAllRejectedApplicationForm] = useGetAllRejectedApplicationFormMutation();
  const [deleteApplicationForm] = useDeleteApplicationFormMutation();

  useEffect(() => {
    fetchData(requestType);
  }, []);

  const fetchData = async (applicationStatus, pageSizeCount = 100,  pageCount = 1) => {
    setLoading(true);
    let result;

    try {
      // let data = {
      //   pageSize: pageSizeCount, 
      //   page: pageCount
      // }
      if(applicationStatus === "PENDING") result = await getAllPendingApplicationForm().unwrap();
      if(applicationStatus === "ACCEPTED") result = await getAllAcceptedApplicationForm().unwrap();
      if(applicationStatus === "REJECTED") result = await getAllRejectedApplicationForm().unwrap();
      console.log(result);
      setApplicationData(result?.data);
      setTotalPages(result?.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false)
      console.log(error);
      toast.error("Error getting application form");
    }
  };

  const handleApplicationFetchData = (status) => {
    setRequestType(status);
    fetchData(status);
  };

  const handleDeleteApplication = async (id) => {
    try {

      await deleteApplicationForm({id}).unwrap();
      
      const updatedList = applicationData.filter(application => application.id !== id);
      setApplicationData(updatedList);
      toast.success("Successfully deleted");

    } catch (error) {

      console.log(error);
      toast.error(error.data.message || `Some unknow error occured`);
      
    }
  }

  const columns = [
    {
      field: "options",
      headerName: "Options",
      flex: 1,
      minWidth: 180,
      filterable: false,
      sortable: false,
      disableExport: true,
      renderCell: (param) => (
        <Box width="100%">
          <IconButton
            sx={{ width: "40px" }}
            onClick={() => {
              setSelectedApplication(param.row);
              setOpenViewModal(true);
            }}
          >
            <VisibilityIcon
              sx={{
                width: "20px",
              }}
            />
          </IconButton>
          <IconButton sx={{ width: "40px" }} onClick={async () => handleDeleteApplication(param.row.id)}>
            <DeleteIcon
              sx={{
                width: "20px",
                color: "#ba000d",
              }}
            />
          </IconButton>
        </Box>
      ),
    },
    { field: "id", headerName: "Sr. No" },
    {
      field: "full_name",
      headerName: "Full Name",
      minWidth: 200,
      // sortable: false,
    },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "aadhaar_card_number",
      headerName: "Aadhaar Number",
      flex: 1,
      minWidth: 140,
    },
    {
      field: "is_previous_saathi_member",
      headerName: "Previous Saathi Member",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param.row.is_previous_saathi_member === true ? "Yes" : "No",
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param.row.gender,
    },
    {
      field: "date_of_birth",
      headerName: "Date Of Birth",
      flex: 1,
      minWidth: 165,
      valueFormatter: ({ value }) => `${ new Date(value).toLocaleDateString()}`,
      renderCell: (param) =>
      new Date(
        param.row.date_of_birth
      ).toLocaleDateString()
       ,
    },
    {
      field: "previous_saathi_card_no",
      headerName: "Previous Card No",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param.row.previous_saathi_card_no
       ,
    },
    {
      field: "have_ration_card",
      headerName: "Have Ration Card",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param?.row?.have_ration_card
       ,
    },
    {
      field: "ration_card_color",
      headerName: "Ration Card Color",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param?.row?.ration_card_color
       ,
    },
    
    {
      field: "ayushman_bharat_card",
      headerName: "Ayushman Bharat Card",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param?.row?.ayushman_bharat_card
       ,
    },
    {
      field: "residential_address",
      headerName: "Address",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param.row.residential_address
       ,
    },
    {
      field: "pincode",
      headerName: "Pincode",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param.row.pincode
       ,
    },
    {
      field: "monthly_salary_range",
      headerName: "Monthly Salary",
      flex: 1,
      minWidth: 165,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (param) =>
        param.row.monthly_salary_range
       ,
    },
    {
      field: "is_retired_person",
      headerName: "Retired Person",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param.row.is_retired_person
       ,
    },
    {
      field: "is_disabled",
      headerName: "Disable",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param.row.is_disabled === true ? "Yes" : "No"
       ,
    },
    {
      field: "disability_description",
      headerName: "Disability Description",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param.row.disability_description
       ,
    },
    {
      field: "marital_status",
      headerName: "Marital Status",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param.row.marital_status
       ,
    },
    {
      field: "type_of_house",
      headerName: "Type of house",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param.row.type_of_house
       ,
    },
    {
      field: "have_children",
      headerName: "Have Children?",
      flex: 1,
      minWidth: 165,
      renderCell: (param) =>
        param.row.have_children === true ? "Yes" : "No"
       ,
    },
    {
      field: "children",
      headerName: "Number of children",
      flex: 1,
      minWidth: 165,
      valueFormatter: ({ value }) => `${value.length}`,
      renderCell: (param) => {
       return param.row?.children.length;
      }
       
       ,
    },
    {
      field: "require_scholarship",
      headerName: "Require Scholarship?",
      flex: 1,
      minWidth: 165,
      renderCell: (param) => {
       return param.row?.require_scholarship === true ? "Yes" : "No";
      }
       
       ,
    },
    {
      field: "registered_member_of_fwice",
      headerName: "Registered member of film union",
      flex: 1,
      minWidth: 165,
      renderCell: (param) => {
       return param.row?.registered_member_of_fwice;
      }
       
       ,
    },
    {
      field: "sector_of_film_industry",
      headerName: "Registered member of film union",
      flex: 1,
      minWidth: 165,
      renderCell: (param) => {
       return param.row?.sector_of_film_industry;
      }
       
       ,
    },
    {
      field: "bank_name",
      headerName: "Bank Name",
      flex: 1,
      minWidth: 165,
      renderCell: (param) => {
       return param.row?.bank_name;
      }
       
       ,
    },
    {
      field: "bank_account_number",
      headerName: "Bank Account No.",
      flex: 1,
      minWidth: 165,
      renderCell: (param) => {
       return param.row?.bank_account_number;
      }
       
       ,
    },
    {
      field: "ifsc_code",
      headerName: "IFSC Code",
      flex: 1,
      minWidth: 165,
      renderCell: (param) => {
       return param.row?.ifsc_code;
      }
       ,
    },
    {
      field: "bank_branch_name",
      headerName: "Bank Branch Name",
      flex: 1,
      minWidth: 165,
      renderCell: (param) => {
       return param.row?.bank_branch_name;
      }
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 165,
      renderCell: (param) => {
       return param.row?.status;
      }
       
       ,
    },
   
  ];

  return (
    <>
      {/* View Modal */}
      <ViewApplication
        openViewModal={openViewModal}
        setOpenViewModal={setOpenViewModal}
        selectedApplication={selectedApplication}
        setApplicationData={setApplicationData}
        applicationData={applicationData}
        requestType={requestType}
      />
      <div style={{ marginBottom: "10px" }}>
        <Button
          variant={requestType === "PENDING" ? "contained" : "outlined"}
          sx={{ margin: "0 8px 0 0" }}
          onClick={() => handleApplicationFetchData("PENDING")}
        >
          Pending
        </Button>
        <Button
          variant={requestType === "ACCEPTED" ? "contained" : "outlined"}
          sx={{ margin: "0 8px" }}
          onClick={() => handleApplicationFetchData("ACCEPTED")}
        >
          Accepted
        </Button>
        <Button
          variant={requestType === "REJECTED" ? "contained" : "outlined"}
          sx={{ margin: "0 8px" }}
          onClick={() => handleApplicationFetchData("REJECTED")}
        >
          Rejected
        </Button>
      </div>

      <Box
        sx={{
          height: "80vh",
          backgroundColor: "#fff",
          borderRadius: "5px",
        }}
      >
        {!loading ? <>{applicationData && (
          <DataGrid
            rows={applicationData}
            columns={columns}
            disableSelectionOnClick
            density="compact"
            components={{ Toolbar: CustomToolbar }}
            slotProps={{ toolbar: { csvOptions: { allColumns: true } } }}
            // page={page}
            // pageSize={pageSize}
            // onPageSizeChange={(e) => {setPageSize(e); fetchData(requestType, e, page)}}
            // onPageChange={(e) => {setPage(e); fetchData(requestType, pageSize , e)}}
            // paginationMode="server" // Use server pagination mode for large datasets
            // rowCount={totalPages * pageSize} 

            // columnVisibilityModel={{
            //   options: false
            // }}
          />
        )}</> : <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
          <h4>Loading...</h4>
        </div>}
        
      </Box>
    </>
  );
};

export default NewApplication;

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
